import React from "react";
import { Route, RouteProps } from "react-router-dom";

// components
import PrivateRoute from "./PrivateRoute";
import Root from "./Root";

// lazy load all the views

// auth
const Login = React.lazy(() => import("../pages/auth/Login"));
const Logout = React.lazy(() => import("../pages/auth/Logout"));
const Confirm = React.lazy(() => import("../pages/auth/Confirm"));
const ForgetPassword = React.lazy(() => import("../pages/auth/ForgetPassword"));
const Register = React.lazy(() => import("../pages/auth/Register"));
const SignInSignUp = React.lazy(() => import("../pages/auth/SignInSignUp"));
const LockScreen = React.lazy(() => import("../pages/auth/LockScreen"));

// auth2
const Login2 = React.lazy(() => import("../pages/auth2/Login2"));
const Logout2 = React.lazy(() => import("../pages/auth2/Logout2"));
const Register2 = React.lazy(() => import("../pages/auth2/Register2"));
const Confirm2 = React.lazy(() => import("../pages/auth2/Confirm2"));
const ForgetPassword2 = React.lazy(
  () => import("../pages/auth2/ForgetPassword2")
);
const LockScreen2 = React.lazy(() => import("../pages/auth2/LockScreen2"));
const SignInSignUp2 = React.lazy(() => import("../pages/auth2/SignInSignUp2"));

// landing
const Landing = React.lazy(() => import("../pages/landing/"));

// dashboard
const Dashboard1 = React.lazy(() => import("../pages/dashboard/Dashboard1/"));
const Dashboard2 = React.lazy(() => import("../pages/dashboard/Dashboard2/"));
const Dashboard3 = React.lazy(() => import("../pages/dashboard/Dashboard3/"));
const Dashboard4 = React.lazy(() => import("../pages/dashboard/Dashboard4/"));

// apps
const CalendarApp = React.lazy(() => import("../pages/apps/Calendar/"));
const Projects = React.lazy(() => import("../pages/apps/Projects/"));
const ProjectDetail = React.lazy(
  () => import("../pages/apps/Projects/Detail/")
);
const ProjectForm = React.lazy(
  () => import("../pages/apps/Projects/ProjectForm")
);

// - chat
const ChatApp = React.lazy(() => import("../pages/apps/Chat/"));

// - ecommece pages
const EcommerceDashboard = React.lazy(
  () => import("../pages/apps/Ecommerce/Dashboard/")
);

// Confirmed bookings
const AllBookings = React.lazy(() => import("../pages/all-bookings"));

// Establisment List
const EstablismentList = React.lazy(() => import("../pages/establishment-list"));

// Laundry
const Laundry = React.lazy(() => import("../pages/laundry"));

// Reported Issues
const ReportedIssues = React.lazy(() => import("../pages/reported-issues"));

// Tasks
const Tasks = React.lazy(() => import("../pages/tasks"));

// Owner Details
const OwnerDetails = React.lazy(() => import("../pages/owner-details"));

// booking's dashboard
const bkDashboard = React.lazy(() => import("../pages/dashboard/Custom"));

const EcommerceProducts = React.lazy(
  () => import("../pages/apps/Ecommerce/Products")
);
const ProductDetails = React.lazy(
  () => import("../pages/apps/Ecommerce/ProductDetails")
);
const ProductEdit = React.lazy(
  () => import("../pages/apps/Ecommerce/ProductEdit")
);
const Customers = React.lazy(() => import("../pages/apps/Ecommerce/Customers"));
const Orders = React.lazy(() => import("../pages/apps/Ecommerce/Orders"));
const OrderDetails = React.lazy(
  () => import("../pages/apps/Ecommerce/OrderDetails")
);
const Sellers = React.lazy(() => import("../pages/apps/Ecommerce/Sellers"));
const Cart = React.lazy(() => import("../pages/apps/Ecommerce/Cart"));
const Checkout = React.lazy(() => import("../pages/apps/Ecommerce/Checkout"));

// - crm pages
const CRMDashboard = React.lazy(() => import("../pages/apps/CRM/Dashboard/"));
const CRMContacts = React.lazy(() => import("../pages/apps/CRM/Contacts/"));
const Opportunities = React.lazy(
  () => import("../pages/apps/CRM/Opportunities/")
);
const CRMLeads = React.lazy(() => import("../pages/apps/CRM/Leads/"));
const CRMCustomers = React.lazy(() => import("../pages/apps/CRM/Customers/"));
// - email
const Inbox = React.lazy(() => import("../pages/apps/Email/Inbox"));
const EmailDetail = React.lazy(() => import("../pages/apps/Email/Detail"));
const EmailCompose = React.lazy(() => import("../pages/apps/Email/Compose"));
// - social
const SocialFeed = React.lazy(() => import("../pages/apps/SocialFeed/"));
// - companies
const Companies = React.lazy(() => import("../pages/apps/Companies/"));
// - tasks
const TaskList = React.lazy(() => import("../pages/apps/Tasks/List/"));
const TaskDetails = React.lazy(() => import("../pages/apps/Tasks/Details"));
const Kanban = React.lazy(() => import("../pages/apps/Tasks/Board/"));
// -contacts
const ContactsList = React.lazy(() => import("../pages/apps/Contacts/List/"));
const ContactsProfile = React.lazy(
  () => import("../pages/apps/Contacts/Profile/")
);
// -tickets
const TicketsList = React.lazy(() => import("../pages/apps/Tickets/List/"));
const TicketsDetails = React.lazy(
  () => import("../pages/apps/Tickets/Details/")
);
// - file
const FileManager = React.lazy(() => import("../pages/apps/FileManager"));

// extra pages
const Starter = React.lazy(() => import("../pages/other/Starter"));
const Timeline = React.lazy(() => import("../pages/other/Timeline"));
const Sitemap = React.lazy(() => import("../pages/other/Sitemap/"));
const Error404 = React.lazy(() => import("../pages/error/Error404"));
const Error404Two = React.lazy(() => import("../pages/error/Error404Two"));
const Error404Alt = React.lazy(() => import("../pages/error/Error404Alt"));
const Error500 = React.lazy(() => import("../pages/error/Error500"));
const Error500Two = React.lazy(() => import("../pages/error/Error500Two"));
// - other
const Invoice = React.lazy(() => import("../pages/other/Invoice"));
const FAQ = React.lazy(() => import("../pages/other/FAQ"));
const SearchResults = React.lazy(() => import("../pages/other/SearchResults/"));
const Upcoming = React.lazy(() => import("../pages/other/Upcoming"));
const Pricing = React.lazy(() => import("../pages/other/Pricing"));
const Gallery = React.lazy(() => import("../pages/other/Gallery/"));
const Maintenance = React.lazy(() => import("../pages/other/Maintenance"));

// uikit
const Buttons = React.lazy(() => import("../pages/uikit/Buttons"));
const Avatars = React.lazy(() => import("../pages/uikit/Avatars"));
const Cards = React.lazy(() => import("../pages/uikit/Cards"));
const Portlets = React.lazy(() => import("../pages/uikit/Portlets"));
const TabsAccordions = React.lazy(
  () => import("../pages/uikit/TabsAccordions")
);
const Progress = React.lazy(() => import("../pages/uikit/Progress"));
const Modals = React.lazy(() => import("../pages/uikit/Modals"));
const Notifications = React.lazy(() => import("../pages/uikit/Notifications"));
const Offcanvases = React.lazy(() => import("../pages/uikit/Offcanvas"));
const Placeholders = React.lazy(() => import("../pages/uikit/Placeholders"));
const Spinners = React.lazy(() => import("../pages/uikit/Spinners"));
const Images = React.lazy(() => import("../pages/uikit/Images"));
const Carousels = React.lazy(() => import("../pages/uikit/Carousel"));
const ListGroups = React.lazy(() => import("../pages/uikit/ListGroups"));
const EmbedVideo = React.lazy(() => import("../pages/uikit/EmbedVideo"));
const Dropdowns = React.lazy(() => import("../pages/uikit/Dropdowns"));
const Ribbons = React.lazy(() => import("../pages/uikit/Ribbons"));
const TooltipsPopovers = React.lazy(
  () => import("../pages/uikit/TooltipsPopovers")
);
const GeneralUI = React.lazy(() => import("../pages/uikit/GeneralUI"));
const Typography = React.lazy(() => import("../pages/uikit/Typography"));
const Grid = React.lazy(() => import("../pages/uikit/Grid"));
const NestableList = React.lazy(() => import("../pages/uikit/NestableList"));
const DragDrop = React.lazy(() => import("../pages/uikit/DragDrop"));
const RangeSliders = React.lazy(() => import("../pages/uikit/RangeSliders"));
const Animation = React.lazy(() => import("../pages/uikit/Animation"));
const TourPage = React.lazy(() => import("../pages/uikit/TourPage"));
const SweetAlerts = React.lazy(() => import("../pages/uikit/SweetAlerts"));
const LoadingButtons = React.lazy(
  () => import("../pages/uikit/LoadingButtons")
);

// widgets
const Widgets = React.lazy(() => import("../pages/uikit/Widgets"));

// icons
const TwoToneIcons = React.lazy(() => import("../pages/icons/TwoToneIcons/"));
const FeatherIcons = React.lazy(() => import("../pages/icons/FeatherIcons/"));
const Dripicons = React.lazy(() => import("../pages/icons/Dripicons/"));
const MDIIcons = React.lazy(() => import("../pages/icons/MDIIcons/"));
const FontAwesomeIcons = React.lazy(
  () => import("../pages/icons/FontAwesomeIcons/")
);
const ThemifyIcons = React.lazy(() => import("../pages/icons/ThemifyIcons/"));
const SimpleLineIcons = React.lazy(
  () => import("../pages/icons/SimpleLineIcons/")
);
const WeatherIcons = React.lazy(() => import("../pages/icons/WeatherIcons/"));

// forms
const BasicForms = React.lazy(() => import("../pages/forms/Basic"));
const FormAdvanced = React.lazy(() => import("../pages/forms/Advanced"));
const FormValidation = React.lazy(() => import("../pages/forms/Validation"));
const FormWizard = React.lazy(() => import("../pages/forms/Wizard"));
const FileUpload = React.lazy(() => import("../pages/forms/FileUpload"));
const Editors = React.lazy(() => import("../pages/forms/Editors"));

// tables
const BasicTables = React.lazy(() => import("../pages/tables/Basic"));
const AdvancedTables = React.lazy(() => import("../pages/tables/Advanced"));

// charts
const ApexChart = React.lazy(() => import("../pages/charts/Apex"));
const ChartJs = React.lazy(() => import("../pages/charts/ChartJs"));

// maps
const GoogleMaps = React.lazy(() => import("../pages/maps/GoogleMaps"));
const VectorMaps = React.lazy(() => import("../pages/maps/VectorMaps"));

// booking calendar
const BookingCalendar = React.lazy(() => import("../pages/calendar/Calendar"));

// error logs
const ErrorLogs = React.lazy(() => import("../pages/error-logs"));

// inventory categories
const InvetoryCategories = React.lazy(
  () => import("../pages/inventory/categories")
);

// inventory items
const InvetoryItems = React.lazy(() => import("../pages/inventory/items"));

// listing type
const ListingType = React.lazy(() => import("../pages/listing-types"));

// locations
const Locations = React.lazy(() => import("../pages/locations"));

// locations
const Users = React.lazy(() => import("../pages/users"));

// locations
const SeoPages = React.lazy(() => import("../pages/seo-pages"));

// suppliers
const Suppliers = React.lazy(() => import("../pages/suppliers-list/suppliers"));

// supplier types
const SupplierTypes = React.lazy(
  () => import("../pages/suppliers-list/supplier-types")
);

// days quoted
const DaysQuoted = React.lazy(() => import("../pages/days-quoted"));

// today
const Today = React.lazy(() => import("../pages/today"));

// turnover
const Reporting = React.lazy(() => import("../pages/reporting"));

// occupancy
const Occupancy = React.lazy(() => import("../pages/occupancy"));

// nights
const Nights = React.lazy(() => import("../pages/nights"));


// Benito
const EstablishmentDetailsDetails = React.lazy(() => import("../pages/establishment-list/pages/establishment-details/details"));
const EstablishmentDetailsConfigurations = React.lazy(() => import("../pages/establishment-list/pages/establishment-details/configurations"));
const EstablishmentDetailsGeneralFeatures = React.lazy(() => import("../pages/establishment-list/pages/establishment-details/general-features"));
const EstablishmentDetailsBedroomBathroomFeatures = React.lazy(() => import("../pages/establishment-list/pages/establishment-details/bedroom-bathroom-features"));
const EstablishmentDetailsKitchenLoungeDiningRoomFeatures = React.lazy(() => import("../pages/establishment-list/pages/establishment-details/kitchen-lounge-dining-room-features"));
const EstablishmentDetailsOutDoorFeatures = React.lazy(() => import("../pages/establishment-list/pages/establishment-details/outdoor-features"));
const EstablishmentDetailsMapLocation = React.lazy(() => import("../pages/establishment-list/pages/establishment-details/map-location"));
const EstablishmentDetailsRegions = React.lazy(() => import("../pages/establishment-list/pages/establishment-details/regions"));
const EstablishmentDetailsPhotos = React.lazy(() => import("../pages/establishment-list/pages/establishment-details/photos"));
const EstablishmentDetailsLiveIntegration = React.lazy(() => import("../pages/establishment-list/pages/establishment-details/live-integration"));
// const EstablishmentDetailsSeasonsRates = React.lazy(() => import("../pages/establishment-list/pages/establishment-details/_seasons-rates"));
const EstablishmentDetailsChannels = React.lazy(() => import("../pages/establishment-list/pages/establishment-details/channels"));
const EstablishmentDetailsSpecials = React.lazy(() => import("../pages/establishment-list/pages/establishment-details/specials"));
const EstablishmentDetailsBookingPolicy = React.lazy(() => import("../pages/establishment-list/pages/establishment-details/booking-policy"));
const EstablishmentDetailsDocuments = React.lazy(() => import("../pages/establishment-list/pages/establishment-details/documents"));
const EstablishmentDetailsBankingDetails = React.lazy(() => import("../pages/establishment-list/pages/establishment-details/banking-details"));

const OperationalInformationDetails = React.lazy(() => import("../pages/establishment-list/pages/operational-information/details"));
const OperationalInformationExtrasAndFees = React.lazy(() => import("../pages/establishment-list/pages/operational-information/extras-and-fees"));
const OperationalInformationPropertyManagerFees = React.lazy(() => import("../pages/establishment-list/pages/operational-information/property-manager-fees"));
const OperationalInformationAddEditSuppliers = React.lazy(() => import("../pages/establishment-list/pages/operational-information/add-edit-suppliers"));
const OperationalInformationApprovedSuppliers = React.lazy(() => import("../pages/establishment-list/pages/operational-information/approved-suppliers"));
const OperationalInformationLinen = React.lazy(() => import("../pages/establishment-list/pages/operational-information/linen"));
const OperationalInformationKeys = React.lazy(() => import("../pages/establishment-list/pages/operational-information/keys"));
const OperationalInformationRates = React.lazy(() => import("../pages/establishment-list/pages/operational-information/rates"));
const InventoryList = React.lazy(() => import("../pages/establishment-list/pages/operational-information/inventory-list"));
const Channels = React.lazy(() => import("../pages/channels"));
const Reviews = React.lazy(() => import("../pages/establishment-list/pages/reviews"));

const AddEstablishment = React.lazy(() => import("../pages/establishment-list/pages/add-establishment"));


const InventoryManageCategories = React.lazy(() => import("../pages/inventory/manage-categories"));
const InventoryManageItems = React.lazy(() => import("../pages/inventory/manage-items"));
const ManageUpdateRequestsUpdates = React.lazy(() => import("../pages/manage-update-requests/updates"));
const ManageUpdateRequestsCancellations = React.lazy(() => import("../pages/manage-update-requests/cancellations"));


// overlaping bookings
const OverlapingBookings = React.lazy(
  () => import("../pages/overlaping-bookings")
);

// channels


export interface RoutesProps {
  path: RouteProps["path"];
  name?: string;
  component?: RouteProps["component"];
  route?: any;
  exact?: RouteProps["exact"];
  icon?: string;
  header?: string;
  roles?: string[];
  children?: RoutesProps[];
}

// root routes
const rootRoute: RoutesProps = {
  path: "/",
  exact: true,
  component: () => <Root />,
  route: Route,
};

//Confirmed bookings
const AllBookingsRoute = {
  path: "/all-bookings",
  name: "All Bookings",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "airplay",
  component: AllBookings,
};

// over laping bookings
const OverlapingBookingsRoute = {
  path: "/overlaping-bookings",
  name: "Overlaping Bookings",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "airplay",
  component: OverlapingBookings,
};

// days quoted
const DaysQuotedRoute = {
  path: "/days-quoted",
  name: "Days Quoted",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "airplay",
  component: DaysQuoted,
};

const ChannelsRoute = {
  path: "/channels",
  name: "Channels",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "airplay",
  component: Channels,
};

// days quoted
const TodayRoute = {
  path: "/today",
  name: "Today",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "airplay",
  component: Today,
};

// Booking calendar
const bookingCalendarRoute = {
  path: "/booking-calendar",
  name: "bkcalendar",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "calendar",
  component: BookingCalendar,
};

// Booking dashboard
const BookingDashboardRoute = {
  path: "/summary",
  name: "bkDashboard",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "airplay",
  component: bkDashboard,
};

// Error table
const ErrorsTable = {
  path: "/error-logs",
  name: "errorLogs",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "terminal",
  component: ErrorLogs,
};

// inventory categories
const InvCategories = {
  path: "/inventory-categories",
  name: "categories",
  route: PrivateRoute,
  roles: ["Admin"],
  component: InvetoryCategories,
};

// inventory items
const InvItems = {
  path: "/inventory-items",
  name: "inventory-items",
  route: PrivateRoute,
  roles: ["Admin"],
  component: InvetoryItems,
};

// turnover
const ReportingRoute = {
  path: "/reporting",
  name: "reporting",
  route: PrivateRoute,
  roles: ["Admin"],
  component: Reporting,
};

// occupancy
const OccupancyRoute = {
  path: "/occupancy",
  name: "occupancy",
  route: PrivateRoute,
  roles: ["Admin"],
  component: Occupancy,
};

// nights
const NightsRoute = {
  path: "/nights",
  name: "nights",
  route: PrivateRoute,
  roles: ["Admin"],
  component: Nights,
};

// listing type
const ListingTypeRoute = {
  path: "/listing-types",
  name: "listing-type",
  route: PrivateRoute,
  roles: ["Admin"],
  component: ListingType,
};

// locations
const LocationsRoute = {
  path: "/locations",
  name: "locations",
  route: PrivateRoute,
  roles: ["Admin"],
  component: Locations,
};

// users
const UsersRoute = {
  path: "/Users",
  name: "users",
  route: PrivateRoute,
  roles: ["Admin"],
  component: Users,
};

// SEO Pages
const SeoPagesRoute = {
  path: "/seo-pages",
  name: "seo-pages",
  route: PrivateRoute,
  roles: ["Admin"],
  component: SeoPages,
};

// Suppliers
const SuppliersRoute = {
  path: "/suppliers",
  name: "suppliers",
  route: PrivateRoute,
  roles: ["Admin"],
  component: Suppliers,
};

// Supplier types
const SupplierTypesRoute = {
  path: "/supplier-types",
  name: "supplier-types",
  route: PrivateRoute,
  roles: ["Admin"],
  component: SupplierTypes,
};

// Laudry
const EstablishmentListRoute = {
  path: "/establishment-list/establishments",
  name: "Establishment List",
  route: PrivateRoute,
  roles: ["Admin"],
  // icon: "airplay",
  component: EstablismentList,
  exact: true,
};

const AddEstablishmentRoute = {
  path: "/establishment-list/add-establishment",
  name: "Add Establishment",
  route: PrivateRoute,
  roles: ["Admin"],
  // icon: "airplay",
  component: AddEstablishment,
  exact: true,
};

// Laudry
const LaundryRoute = {
  path: "/laundry",
  name: "Laundry",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "airplay",
  component: Laundry,
};

// Tasks
const TasksRoute = {
  path: "/tasks",
  name: "Tasks",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "airplay",
  component: Tasks,
};

const OwnerDetailsRoute = {
  path: "/establishment-list/owner-details",
  name: "Owner Details",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "airplay",
  component: OwnerDetails,
  exact: true,
};

// Booking dashboard
const ReportedIssuesRoute = {
  path: "/reported-issues",
  name: "Reported Issues",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "airplay",
  component: ReportedIssues,
};

const establishmentDetailsRoutes: RoutesProps = {
  name: "Establishment Details",
  header: 'Establishment Details',
  path: "/establishment-list/establishment-details",
  exact: true,
  component: EstablismentList,
  children: [
    {
      path: "/establishment-list/establishment-details/general-information",
      name: 'Details',
      exact: true,
      component: EstablishmentDetailsDetails,
      route: PrivateRoute,
    },
    {
      path: "/establishment-list/establishment-details/configurations",
      name: 'Configurations',
      exact: true,
      component: EstablishmentDetailsConfigurations,
      route: PrivateRoute,
    },
    {
      path: "/establishment-list/establishment-details/general-features",
      name: 'General Features',
      component: EstablishmentDetailsGeneralFeatures,
      route: PrivateRoute,
    },
    {
      path: "/establishment-list/establishment-details/bedroom-bathroom-features",
      name: 'Bedroom Bathroom Features',
      component: EstablishmentDetailsBedroomBathroomFeatures,
      route: PrivateRoute,
    },
    {
      path: "/establishment-list/establishment-details/kitchen-lounge-dining-room",
      name: 'Kitchen/Lounge/Dining Room',
      component: EstablishmentDetailsKitchenLoungeDiningRoomFeatures,
      route: PrivateRoute,
    },
    {
      path: "/establishment-list/establishment-details/outdoor-features",
      name: 'Outdoor Features',
      component: EstablishmentDetailsOutDoorFeatures,
      route: PrivateRoute,
    },
    {
      path: "/establishment-list/establishment-details/map-location",
      name: 'Map Location',
      component: EstablishmentDetailsMapLocation,
      route: PrivateRoute,
    },
    {
      path: "/establishment-list/establishment-details/regions",
      name: 'Gegions',
      component: EstablishmentDetailsRegions,
      route: PrivateRoute,
    },
    {
      path: "/establishment-list/establishment-details/photos",
      name: 'Photos',
      component: EstablishmentDetailsPhotos,
      route: PrivateRoute,
    },
    {
      path: "/establishment-list/establishment-details/live-integration",
      name: 'Live Integration',
      component: EstablishmentDetailsLiveIntegration,
      route: PrivateRoute,
    },
    {
      path: "/establishment-list/establishment-details/channels",
      name: 'Channels',
      component: EstablishmentDetailsChannels,
      route: PrivateRoute,
    },
    {
      path: "/establishment-list/establishment-details/specials",
      name: 'Child Policy',
      component: EstablishmentDetailsSpecials,
      route: PrivateRoute,
    },
    {
      path: "/establishment-list/establishment-details/booking-policy",
      name: 'Child Policy',
      component: EstablishmentDetailsBookingPolicy,
      route: PrivateRoute,
    },
    {
      path: "/establishment-list/establishment-details/documents",
      name: 'Documents',
      component: EstablishmentDetailsDocuments,
      route: PrivateRoute,
    },
    {
      path: "/establishment-list/establishment-details/banking-details",
      name: 'Banking Details',
      component: EstablishmentDetailsBankingDetails,
      route: PrivateRoute,
    },
  ],

}

const operationalInformationRoutes: RoutesProps = {
  name: "Operational Information",
  header: 'Operational Information',
  path: "/operational-information",
  exact: true,
  children: [
    {
      path: "/establishment-list/operational-information/details",
      name: 'Details',
      component: OperationalInformationDetails,
      route: PrivateRoute,
      exact: true,
    },
    {
      path: "/establishment-list/operational-information/extras-and-fees",
      name: 'Extras and Fees',
      component: OperationalInformationExtrasAndFees,
      route: PrivateRoute,
    },
    {
      path: "/establishment-list/operational-information/property-manager-fees",
      name: 'Property Manager Fees',
      component: OperationalInformationPropertyManagerFees,
      route: PrivateRoute,
    },
    {
      path: "/establishment-list/operational-information/add-edit-suppliers",
      name: 'Add/Edit Suppliers',
      component: OperationalInformationAddEditSuppliers,
      route: PrivateRoute,
    },
    {
      path: "/establishment-list/operational-information/approved-suppliers",
      name: 'Approved Suppliers',
      component: OperationalInformationApprovedSuppliers,
      route: PrivateRoute,
    },
    {
      path: "/establishment-list/operational-information/linen",
      name: 'Linen',
      component: OperationalInformationLinen,
      route: PrivateRoute,
    },
    {
      path: "/establishment-list/operational-information/keys",
      name: 'Keys',
      component: OperationalInformationKeys,
      route: PrivateRoute,
    },
    {
      path: "/establishment-list/operational-information/inventory-list",
      name: 'Inventory List',
      component: InventoryList,
      route: PrivateRoute,
    },
    {
      path: "/establishment-list/operational-information/rates",
      name: 'Rates',
      component: OperationalInformationRates,
      route: PrivateRoute,
    },
  ]
}

const adminRoutes: RoutesProps = {
  name: "Admin",
  header: 'Admin',
  path: "/admin",
  exact: true,
  children: [
    {
      path: "/admin/manage-inventory-categories",
      name: 'Manage Inventory Categories',
      component: InventoryManageCategories,
      route: PrivateRoute,
      exact: true,
    },
    {
      path: "/admin/manage-inventory-items",
      name: 'Manage Inventory Items',
      component: InventoryManageItems,
      route: PrivateRoute,
      exact: true,
    },
    {
      path: "/admin/manage-update-requests/updates",
      name: 'Manage Updates',
      component: ManageUpdateRequestsUpdates,
      route: PrivateRoute,
      exact: true,
    },
    {
      path: "/admin/manage-update-requests/cancellations",
      name: 'Manage Cancellations',
      component: ManageUpdateRequestsCancellations,
      route: PrivateRoute,
      exact: true,
    },
  ]
}


const reviewsRoutes: RoutesProps = {
  name: "Reviews",
  header: 'Reviews',
  path: "/establishment-list/reviews",
  component: Reviews,
  route: PrivateRoute,
  exact: true,
}


// dashboards
const dashboardRoutes: RoutesProps = {
  path: "/dashboard",
  name: "Dashboards",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/dashboard-1",
      name: "Dashboard 1",
      component: Dashboard1,
      route: PrivateRoute,
    },
    {
      path: "/dashboard-2",
      name: "Dashboard 2",
      component: Dashboard2,
      route: PrivateRoute,
    },
    {
      path: "/dashboard-3",
      name: "Dashboard 3",
      component: Dashboard3,
      route: PrivateRoute,
    },
    {
      path: "/dashboard-4",
      name: "Dashboard 4",
      component: Dashboard4,
      route: PrivateRoute,
    },
  ],
};

const calendarAppRoutes: RoutesProps = {
  path: "/apps/calendar",
  name: "Calendar",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "calendar",
  component: CalendarApp,
  header: "Apps",
};

const chatAppRoutes = {
  path: "/apps/chat",
  name: "Chat",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "message-square",
  component: ChatApp,
};

const ecommerceAppRoutes = {
  path: "/apps/ecommerce",
  name: "eCommerce",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "shopping-cart",
  children: [
    {
      path: "/apps/ecommerce/dashboard",
      name: "Products",
      component: EcommerceDashboard,
      route: PrivateRoute,
    },
    {
      path: "/apps/ecommerce/products",
      name: "Products",
      component: EcommerceProducts,
      route: PrivateRoute,
    },
    {
      path: "/apps/ecommerce/product-details",
      name: "Product Details",
      component: ProductDetails,
      route: PrivateRoute,
    },
    {
      path: "/apps/ecommerce/edit-product",
      name: "Product Edit",
      component: ProductEdit,
      route: PrivateRoute,
    },
    {
      path: "/apps/ecommerce/customers",
      name: "Customers",
      component: Customers,
      route: PrivateRoute,
    },
    {
      path: "/apps/ecommerce/orders",
      name: "Orders",
      component: Orders,
      route: PrivateRoute,
    },
    {
      path: "/apps/ecommerce/order/details",
      name: "Order Details",
      component: OrderDetails,
      route: PrivateRoute,
    },
    {
      path: "/apps/ecommerce/sellers",
      name: "Sellers",
      component: Sellers,
      route: PrivateRoute,
    },
    {
      path: "/apps/ecommerce/shopping-cart",
      name: "Shopping Cart",
      component: Cart,
      route: PrivateRoute,
    },
    {
      path: "/apps/ecommerce/checkout",
      name: "Checkout",
      component: Checkout,
      route: PrivateRoute,
    },
  ],
};

const crmAppRoutes = {
  path: "/apps/crm",
  name: "CRM",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "users",
  children: [
    {
      path: "/apps/crm/dashboard",
      name: "Dashboard",
      component: CRMDashboard,
      route: PrivateRoute,
    },
    {
      path: "/apps/crm/contacts",
      name: "Contacts",
      component: CRMContacts,
      route: PrivateRoute,
    },
    {
      path: "/apps/crm/opportunities",
      name: "Opportunities",
      component: Opportunities,
      route: PrivateRoute,
    },
    {
      path: "/apps/crm/leads",
      name: "Leads",
      component: CRMLeads,
      route: PrivateRoute,
    },
    {
      path: "/apps/crm/customers",
      name: "Customers",
      component: CRMCustomers,
      route: PrivateRoute,
    },
  ],
};

const emailAppRoutes = {
  path: "/apps/email",
  name: "Email",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "mail",
  children: [
    {
      path: "/apps/email/inbox",
      name: "Inbox",
      component: Inbox,
      route: PrivateRoute,
    },
    {
      path: "/apps/email/details",
      name: "Email Details",
      component: EmailDetail,
      route: PrivateRoute,
    },
    {
      path: "/apps/email/compose",
      name: "Compose Email",
      component: EmailCompose,
      route: PrivateRoute,
    },
  ],
};

const socialAppRoutes = {
  path: "/apps/social-feed",
  name: "Social Feed",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "rss",
  component: SocialFeed,
};

const companiesAppRoutes = {
  path: "/apps/companies",
  name: "Companies",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "activity",
  component: Companies,
};

const projectAppRoutes = {
  path: "/apps/projects",
  name: "Projects",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "uil-briefcase",

  children: [
    {
      path: "/apps/projects/list",
      name: "List",
      component: Projects,
      route: PrivateRoute,
    },
    {
      path: "/apps/projects/:id/details",
      name: "Detail",
      component: ProjectDetail,
      route: PrivateRoute,
    },
    {
      path: "/apps/projects/create",
      name: "Create Project",
      component: ProjectForm,
      route: PrivateRoute,
    },
  ],
};

const taskAppRoutes = {
  path: "/apps/tasks",
  name: "Tasks",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "clipboard",
  children: [
    {
      path: "/apps/tasks/list",
      name: "Task List",
      component: TaskList,
      route: PrivateRoute,
    },
    {
      path: "/apps/tasks/details",
      name: "Task List",
      component: TaskDetails,
      route: PrivateRoute,
    },
    {
      path: "/apps/tasks/kanban",
      name: "Kanban",
      component: Kanban,
      route: PrivateRoute,
    },
  ],
};

const contactsRoutes = {
  path: "/apps/contacts",
  name: "Contacts",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "book",
  children: [
    {
      path: "/apps/contacts/list",
      name: "Task List",
      component: ContactsList,
      route: PrivateRoute,
    },
    {
      path: "/apps/contacts/profile",
      name: "Profile",
      component: ContactsProfile,
      route: PrivateRoute,
    },
  ],
};

const ticketsRoutes = {
  path: "/apps/tickets",
  name: "Tickets",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "aperture",
  children: [
    {
      path: "/apps/tickets/list",
      name: "List",
      component: TicketsList,
      route: PrivateRoute,
    },
    {
      path: "/apps/tickets/details",
      name: "Details",
      component: TicketsDetails,
      route: PrivateRoute,
    },
  ],
};

const fileAppRoutes = {
  path: "/apps/file-manager",
  name: "File Manager",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "folder-plus",
  component: FileManager,
};

const appRoutes = [
  calendarAppRoutes,
  chatAppRoutes,
  ecommerceAppRoutes,
  crmAppRoutes,
  emailAppRoutes,
  socialAppRoutes,
  companiesAppRoutes,
  projectAppRoutes,
  taskAppRoutes,
  contactsRoutes,
  ticketsRoutes,
  fileAppRoutes,
];

// pages
const extrapagesRoutes = {
  path: "/pages",
  name: "Pages",
  icon: "package",
  header: "Custom",
  children: [
    {
      path: "/pages/starter",
      name: "Starter",
      component: Starter,
      route: PrivateRoute,
    },
    {
      path: "/pages/timeline",
      name: "Timeline",
      component: Timeline,
      route: PrivateRoute,
    },
    {
      path: "/pages/sitemap",
      name: "Sitemap",
      component: Sitemap,
      route: PrivateRoute,
    },
    {
      path: "/pages/invoice",
      name: "Invoice",
      component: Invoice,
      route: PrivateRoute,
    },
    {
      path: "/pages/faq",
      name: "FAQ",
      component: FAQ,
      route: PrivateRoute,
    },
    {
      path: "/pages/serach-results",
      name: "Search Results",
      component: SearchResults,
      route: PrivateRoute,
    },
    {
      path: "/pages/pricing",
      name: "Pricing",
      component: Pricing,
      route: PrivateRoute,
    },
    {
      path: "/pages/gallery",
      name: "Gallery",
      component: Gallery,
      route: PrivateRoute,
    },
    {
      path: "/pages/error-404-alt",
      name: "Error - 404-alt",
      component: Error404Alt,
      route: PrivateRoute,
    },
  ],
};

// ui
const uiRoutes = {
  path: "/ui",
  name: "Components",
  icon: "pocket",
  header: "UI Elements",
  children: [
    {
      path: "/ui/base",
      name: "Base UI",
      children: [
        {
          path: "/ui/buttons",
          name: "Buttons",
          component: Buttons,
          route: PrivateRoute,
        },
        {
          path: "/ui/cards",
          name: "Cards",
          component: Cards,
          route: PrivateRoute,
        },
        {
          path: "/ui/avatars",
          name: "Avatars",
          component: Avatars,
          route: PrivateRoute,
        },
        {
          path: "/ui/portlets",
          name: "Portlets",
          component: Portlets,
          route: PrivateRoute,
        },
        {
          path: "/ui/tabs-accordions",
          name: "Tabs & Accordions",
          component: TabsAccordions,
          route: PrivateRoute,
        },
        {
          path: "/ui/progress",
          name: "Progress",
          component: Progress,
          route: PrivateRoute,
        },
        {
          path: "/ui/modals",
          name: "Modals",
          component: Modals,
          route: PrivateRoute,
        },
        {
          path: "/ui/notifications",
          name: "Notifications",
          component: Notifications,
          route: PrivateRoute,
        },
        {
          path: "/ui/offcanvas",
          name: "Offcanvas",
          component: Offcanvases,
          route: PrivateRoute,
        },
        {
          path: "/ui/placeholders",
          name: "Placeholders",
          component: Placeholders,
          route: PrivateRoute,
        },
        {
          path: "/ui/spinners",
          name: "Spinners",
          component: Spinners,
          route: PrivateRoute,
        },
        {
          path: "/ui/images",
          name: "Images",
          component: Images,
          route: PrivateRoute,
        },
        {
          path: "/ui/carousel",
          name: "Carousel",
          component: Carousels,
          route: PrivateRoute,
        },
        {
          path: "/ui/listgroups",
          name: "List Groups",
          component: ListGroups,
          route: PrivateRoute,
        },
        {
          path: "/ui/embedvideo",
          name: "EmbedVideo",
          component: EmbedVideo,
          route: PrivateRoute,
        },
        {
          path: "/ui/dropdowns",
          name: "Dropdowns",
          component: Dropdowns,
          route: PrivateRoute,
        },
        {
          path: "/ui/ribbons",
          name: "Ribbons",
          component: Ribbons,
          route: PrivateRoute,
        },
        {
          path: "/ui/tooltips-popovers",
          name: "Tooltips & Popovers",
          component: TooltipsPopovers,
          route: PrivateRoute,
        },
        {
          path: "/ui/typography",
          name: "Typography",
          component: Typography,
          route: PrivateRoute,
        },
        {
          path: "/ui/grid",
          name: "Grid",
          component: Grid,
          route: PrivateRoute,
        },
        {
          path: "/ui/general",
          name: "General UI",
          component: GeneralUI,
          route: PrivateRoute,
        },
      ],
    },
    {
      path: "/ui/extended",
      name: "Extended UI",
      children: [
        {
          path: "/extended-ui/nestable",
          name: "Nestable List",
          component: NestableList,
          route: PrivateRoute,
        },
        {
          path: "/extended-ui/dragdrop",
          name: "Drag and Drop",
          component: DragDrop,
          route: PrivateRoute,
        },
        {
          path: "/extended-ui/rangesliders",
          name: "Range Sliders",
          component: RangeSliders,
          route: PrivateRoute,
        },
        {
          path: "/extended-ui/animation",
          name: "Animation",
          component: Animation,
          route: PrivateRoute,
        },
        {
          path: "/extended-ui/sweet-alert",
          name: "Sweet Alert",
          component: SweetAlerts,
          route: PrivateRoute,
        },
        {
          path: "/extended-ui/tour",
          name: "Tour Page",
          component: TourPage,
          route: PrivateRoute,
        },
        {
          path: "/extended-ui/loading-buttons",
          name: "Loading Buttons",
          component: LoadingButtons,
          route: PrivateRoute,
        },
      ],
    },
    {
      path: "/ui/widgets",
      name: "Widgets",
      component: Widgets,
      route: PrivateRoute,
    },
    {
      path: "/ui/icons",
      name: "Icons",
      children: [
        {
          path: "/ui/icons/two-tone",
          name: "Two Tone Icons",
          component: TwoToneIcons,
          route: PrivateRoute,
        },
        {
          path: "/ui/icons/feather",
          name: "Feather Icons",
          component: FeatherIcons,
          route: PrivateRoute,
        },
        {
          path: "/ui/icons/dripicons",
          name: "Dripicons",
          component: Dripicons,
          route: PrivateRoute,
        },
        {
          path: "/ui/icons/mdi",
          name: "Material Design",
          component: MDIIcons,
          route: PrivateRoute,
        },
        {
          path: "/ui/icons/font-awesome",
          name: "Font Awesome 5",
          component: FontAwesomeIcons,
          route: PrivateRoute,
        },
        {
          path: "/ui/icons/themify",
          name: "Themify",
          component: ThemifyIcons,
          route: PrivateRoute,
        },
        {
          path: "/ui/icons/simple-line",
          name: "Simple Line Icons",
          component: SimpleLineIcons,
          route: PrivateRoute,
        },
        {
          path: "/ui/icons/weather",
          name: "Weather Icons",
          component: WeatherIcons,
          route: PrivateRoute,
        },
      ],
    },
    {
      path: "/ui/forms",
      name: "Forms",
      children: [
        {
          path: "/ui/forms/basic",
          name: "Basic Elements",
          component: BasicForms,
          route: PrivateRoute,
        },
        {
          path: "/ui/forms/advanced",
          name: "Form Advanced",
          component: FormAdvanced,
          route: PrivateRoute,
        },
        {
          path: "/ui/forms/validation",
          name: "Form Validation",
          component: FormValidation,
          route: PrivateRoute,
        },
        {
          path: "/ui/forms/wizard",
          name: "Form Wizard",
          component: FormWizard,
          route: PrivateRoute,
        },
        {
          path: "/ui/forms/upload",
          name: "File Upload",
          component: FileUpload,
          route: PrivateRoute,
        },
        {
          path: "/ui/forms/editors",
          name: "Editors",
          component: Editors,
          route: PrivateRoute,
        },
      ],
    },
    {
      path: "/ui/tables",
      name: "Tables",
      children: [
        {
          path: "/ui/tables/basic",
          name: "Basic",
          component: BasicTables,
          route: PrivateRoute,
        },
        {
          path: "/ui/tables/advanced",
          name: "Advanced",
          component: AdvancedTables,
          route: PrivateRoute,
        },
      ],
    },
    {
      path: "/ui/charts",
      name: "Charts",
      children: [
        {
          path: "/ui/charts/apex",
          name: "Apex",
          component: ApexChart,
          route: PrivateRoute,
        },
        {
          path: "/ui/charts/chartjs",
          name: "Chartjs",
          component: ChartJs,
          route: PrivateRoute,
        },
      ],
    },
    {
      path: "/ui/maps",
      name: "Maps",
      children: [
        {
          path: "/ui/googlemaps",
          name: "Google Maps",
          component: GoogleMaps,
          route: PrivateRoute,
        },
        {
          path: "/ui/vectorMaps",
          name: "Google Maps",
          component: VectorMaps,
          route: PrivateRoute,
        },
      ],
    },
  ],
};

// auth
const authRoutes: RoutesProps[] = [
  {
    path: "/auth/login",
    name: "Login",
    component: Login,
    route: Route,
  },
  {
    path: "/auth/register",
    name: "Register",
    component: Register,
    route: Route,
  },
  {
    path: "/auth/confirm",
    name: "Confirm",
    component: Confirm,
    route: Route,
  },
  {
    path: "/auth/forget-password",
    name: "Forget Password",
    component: ForgetPassword,
    route: Route,
  },
  {
    path: "/auth/signin-signup",
    name: "SignIn-SignUp",
    component: SignInSignUp,
    route: Route,
  },
  {
    path: "/auth/lock-screen",
    name: "Lock Screen",
    component: LockScreen,
    route: Route,
  },
  {
    path: "/auth/logout",
    name: "Logout",
    component: Logout,
    route: Route,
  },
  {
    path: "/auth/login2",
    name: "Login2",
    component: Login2,
    route: Route,
  },
  {
    path: "/auth/logout2",
    name: "Logout2",
    component: Logout2,
    route: Route,
  },
  {
    path: "/auth/register2",
    name: "Register2",
    component: Register2,
    route: Route,
  },
  {
    path: "/auth/confirm2",
    name: "Confirm2",
    component: Confirm2,
    route: Route,
  },
  {
    path: "/auth/forget-password2",
    name: "Forget Password2",
    component: ForgetPassword2,
    route: Route,
  },
  {
    path: "/auth/signin-signup2",
    name: "SignIn-SignUp2",
    component: SignInSignUp2,
    route: Route,
  },
  {
    path: "/auth/lock-screen2",
    name: "Lock Screen2",
    component: LockScreen2,
    route: Route,
  },
];

// public routes
const otherPublicRoutes = [
  {
    path: "/landing",
    name: "landing",
    component: Landing,
    route: Route,
  },
  {
    path: "/maintenance",
    name: "Maintenance",
    component: Maintenance,
    route: Route,
  },
  {
    path: "/error-404",
    name: "Error - 404",
    component: Error404,
    route: Route,
  },
  {
    path: "/error-404-two",
    name: "Error - 404 Two",
    component: Error404Two,
    route: Route,
  },
  {
    path: "/error-500",
    name: "Error - 500",
    component: Error500,
    route: Route,
  },
  {
    path: "/error-500-two",
    name: "Error - 500 Two",
    component: Error500Two,
    route: Route,
  },
  {
    path: "/upcoming",
    name: "Coming Soon",
    component: Upcoming,
    route: Route,
  },
];

// flatten the list of all nested routes
const flattenRoutes = (routes: RoutesProps[]) => {
  let flatRoutes: RoutesProps[] = [];

  routes = routes || [];
  routes.forEach((item: RoutesProps) => {
    flatRoutes.push(item);

    if (typeof item.children !== "undefined") {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

// All routes
const authProtectedRoutes = [
  rootRoute,
  dashboardRoutes,
  ...appRoutes,
  extrapagesRoutes,
  uiRoutes,
  AllBookingsRoute,
  ErrorsTable,
  bookingCalendarRoute,
  BookingDashboardRoute,
  ReportedIssuesRoute,
  EstablishmentListRoute,
  AddEstablishmentRoute,
  establishmentDetailsRoutes,
  operationalInformationRoutes,
  adminRoutes,
  reviewsRoutes,
  TasksRoute,
  OwnerDetailsRoute,
  LaundryRoute,
  InvCategories,
  InvItems,
  ListingTypeRoute,
  LocationsRoute,
  UsersRoute,
  SeoPagesRoute,
  SuppliersRoute,
  SupplierTypesRoute,
  DaysQuotedRoute,
  TodayRoute,
  ReportingRoute,
  OccupancyRoute,
  NightsRoute,
  OverlapingBookingsRoute,
  ChannelsRoute,
];
const publicRoutes = [...authRoutes, ...otherPublicRoutes];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);
export {
  publicRoutes,
  authProtectedRoutes,
  authProtectedFlattenRoutes,
  publicProtectedFlattenRoutes,
};
